<template>
  <BackofficeBase :loader_prop="loader_prop">
    <DistributionPointMenu :point_id_prod="point_id_prod" />
    <router-link
      :to="{
        path: '/backoffice/point_management',
        query: { id: view_data.distribution_point_data.id },
      }"
      >ניהול יחידות</router-link
    >
    <br />
    <router-link
      :to="{
        path: '/backoffice/viewing_endusers_admin',
        query: { id: view_data.distribution_point_data.id },
      }"
    >
      צפייה בלקוחות קצה</router-link
    >
    <br />
    <router-link
      :to="{
        path: '/backoffice/backoffice_login_as_point',
        query: { id: view_data.distribution_point_data.id },
      }"
    >
      כניסה כמשתמש נקודת חלוקה</router-link
    >
    <div v-if="view_data.distribution_point.length != 0">
      <p v-if="view_data != null">
        {{ view_data.distribution_point[0].users[0].s }} :מספר לקוחות קצה
      </p>
      <p v-if="view_data != null">
        {{ view_data.distribution_point[0].ts }} :תאריך הצטרפות
      </p>
      <br />
      <div v-if="view_data != null">
        <div
          v-for="units in view_data.distribution_point"
          :key="units.units_id"
          class="point_deatails"
        >
          <p class="unis_title">{{ units.unit_name }} :יחידה</p>
          <p v-if="units.battery_status != null">
            {{ units.battery_status }} %:מצב סוללה
          </p>
          <p v-if="units.battery_status == null">
            מצב סוללה: <spain style="color: red"> אין נתונים</spain>
          </p>
          <p>{{ units.jumbo_amount }} :(Jumbo) סה"כ מספר תיבות 40*45</p>
          <p>{{ units.jumbo_catchs }} :מתוכם תפוסות</p>
          <p>{{ units.large_amount }} :(Large) סה"כ מספר תיבות 40*45</p>
          <p>{{ units.large_catchs }} :מתוכם תפוסות</p>
          <p>{{ units.medium_amount }} :(Medium) סה"כ מספר תיבות 40*25</p>
          <p>{{ units.medium_catchs }} :מתוכם תפוסות</p>
          <p>{{ units.small_amount }} :(Small) סה"כ מספר תיבות 20*11</p>
          <p>{{ units.small_catchs }} :מתוכם תפוסות</p>
          <p>{{ units.faulty_boxes }} :תיבות תקולות</p>
          <p>{{ units.unit_id }} :מספר מזהה</p>
          <p>{{ units.ip_address }} :IP</p>
          <p>{{ units.mac_address }} :MAC/SERIAL</p>
          <br />
        </div>
      </div>

      <br />
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";
import DistributionPointMenu from "./DistributionPointMenu";

export default {
  name: "BackofficeDistributionPoint",
  components: {
    BackofficeBase,
    DistributionPointMenu,
  },

  data() {
    return {
      loader_prop: true,
      view_data: [],
      // point_id_prod : 0,
      id: "",
    };
  },

  async mounted() {
    // this.id = this.$route.query.id
    this.point_id_prod = this.$route.query.id;
    let backendModel = new BackendModel();
    let view_data = await backendModel.backendRequest(
      "/Api/service/backoffice/get_distribution_point",
      { id: this.point_id_prod }
    );
    this.view_data = view_data.data.view_data;
    console.log(view_data);
    this.loader_prop = false;
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
</style>

